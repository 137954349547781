<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-form
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>

            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="title"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`title.${k}`"
                    :name="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model="form.title[k]"
                        :placeholder="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.start_date')"
                vid="start_date"

              >
                <b-form-group>
                  <DatePicker
                    v-model="form.start_time"
                    :label="$t('labels.start_date')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.time')"
                vid="start_time"

              >
                <b-form-group>
                  <TimePicker
                    v-model="start_time"
                    :label="$t('labels.time')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.end_date')"
                vid="end_date"

              >
                <b-form-group>
                  <DatePicker
                    v-model="form.end_time"
                    :label="$t('labels.end_date')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.time')"
                vid="end_time"

              >
                <b-form-group>
                  <TimePicker
                    v-model="end_time"
                    :label="$t('labels.time')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>





            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {

    return {
      types: [],
      config: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
      },
      icon: null,
      start_time: null,
      end_time: null,


      form: {
        title: {
          en: null,
          ar: null,
        },
        start_time: null,
        end_time: null,

      },
    }
  },

  watch: {



  },
  created() {
    if (this.isEdit) {
      this.loadData()
    }
  },
  methods: {

    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            title: data.title,
            start_time: data.start_time,
            end_time: data.end_time,


          }

          this.start_time = this.form.start_time.substring(this.form.start_time.indexOf(' ') + 1)
          this.end_time = this.form.end_time.substring(this.form.end_time.indexOf(' ') + 1)


        })
    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if($form.start_time){
        $form.start_time = $form.start_time + ' ' + this.start_time
      }
      if($form.end_time){
        $form.end_time = $form.end_time + ' ' + this.end_time
      }

      return $form
    },
  },

}
</script>
